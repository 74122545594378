<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.type"
          label="Type"
          outlined
          item-text="name"
          item-value="value"
          :items="typeOptions"
          :rules="[required]"
          class="mt-5"
        />

        <div>
          <h3 class="d-flex justify-space-between">
            Enabled Features
            <a @click="addAllModules">ADD ALL FEATURES</a>
          </h3>
          <div
            v-for="mOptions in moduleFeaturesOptions"
            :key="mOptions.id"
            class="d-flex justify-space-between align-center"
          >
            <v-select
              v-model="form.feature_ids"
              :items="mOptions.features"
              item-text="label"
              item-value="value"
              :label="`${t(mOptions.name)} Module`"
              chips
              multiple
            ></v-select>

            <div style="min-width: 130px;">
              <a
                @click="allowAllModuleFeatures(mOptions)"
              >
                Allow all
              </a>

              <a
                class="ml-2"
                @click="blockAllModuleFeatures(mOptions)"
              >
                Block all
              </a>
            </div>
          </div>
        </div>

        <h3>General Infomation</h3>

        <v-select
          v-model="form.site_id"
          :items="siteOptions"
          item-text="title"
          item-value="value"
          label="Site"
          :rules="[required]"
          outlined
          chips
        ></v-select>

        <v-text-field
          v-model="form.business_name"
          label="Business Name"
          outlined
          hint="Printed on Official Docs."
          :rules="[required]"
          class="mt-5"
        />

        <v-text-field
          v-model="form.nicename"
          label="Nice Name"
          outlined
          hint="Simple and recognizable"
          :rules="[required]"
          class="mt-5"
        />
        <v-text-field
          v-model="form.slug"
          label="Unique slug for nice URL (e.g. MIX)"
          hint="This affects sub domain white label ({BLUE}.portal. - case insensitive). The frontend needs this determine current sub domain's distributor. Keep it unchanged once confirmed. Otherwise, the existing url sent will fail. It no longer affect the medical prefix."
          outlined
          :rules="[required]"
          class="mt-5"
        ></v-text-field>

        <v-switch
          v-model="form.active"
          color="primary"
          :label="form.active ? 'Active' : 'Inactive'"
        ></v-switch>

        <p>Don't forget to update the distributor profile!</p>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

import useModuleList from '@/modules/feature/views/useModuleList'
import useSites from '@/modules/site/views/useSites'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    // const Syncfield = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('type', 'generic'),
      ...field('feature_ids', []),
      ...field('business_name', ''),
      ...field('nicename', ''),
      ...field('site_id', 1),

      // ...field('name_to_medical_group', 'Mixup Solution Company Limited'),
      ...field('slug', ''),
      ...field('active', true),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    if (props.resource && props.resource.features) {
      form.value.feature_ids = props.resource.features.map(f => f.id)
    }

    const typeOptions = ref([
      {
        name: 'Generic',
        value: 'generic',
      },
      {
        name: 'Company',
        value: 'company',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) {
        emit('submit', form.value)
      }
    }

    const { moduleFeaturesOptions, loadModules, mapOptions } = useModuleList()
    loadModules({ take: 999 }).then(() => {
      mapOptions()
    })

    const allowAllModuleFeatures = m => {
      m.features.forEach(option => {
        if (!form.value.feature_ids.find(id => id == option.value)) {
          form.value.feature_ids.push(option.value)
        }
      })
    }

    const blockAllModuleFeatures = m => {
      m.features.forEach(option => {
        const ids = form.value.feature_ids
        const index = ids.findIndex(id => id == option.value)
        if (index >= 0) {
          ids.splice(index, 1)
        }
      })
    }

    const addAllModules = () => moduleFeaturesOptions.value.forEach(allowAllModuleFeatures)

    const { siteOptions, loadSites } = useSites()

    loadSites()

    return {
      form,
      formElem,
      validate,
      required,

      t,

      typeOptions,
      siteOptions,
      moduleFeaturesOptions,

      allowAllModuleFeatures,
      blockAllModuleFeatures,
      addAllModules,
    }
  },
}
</script>
@/modules/feature/views/useModuleList
