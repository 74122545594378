<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <h2> Profile </h2>
        <v-text-field
          v-model="form.email"
          label="Email"
          outlined
          :rules="[required]"
          class="mt-5"
        />
        <v-text-field
          v-model="form.name"
          label="Name - editable by frontend"
          outlined
          :rules="[required]"
          class="mt-5"
        />
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="form.country_code"
              label="Country Code"
              outlined
              item-text="name"
              item-value="value"
              :items="['852','853','86']"
              :rules="[]"
              class="mt-5"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.phone"
              label="Phone"
              outlined
              :rules="[required]"
              class="mt-5"
            />
          </v-col>
        </v-row>
        <v-select
          v-if="countries"
          v-model="form.country_id"
          label="Country"
          outlined
          item-text="name.en"
          :rules="[(v) => !!v || 'Item is required']"
          item-value="id"
          :items="countries.records"
          required
          class="mt-5"
        />
        <v-select
          v-if="industries"
          v-model="form.industry_id"
          label="Industry"
          outlined
          item-text="name.en"
          :rules="[(v) => !!v || 'Item is required']"
          item-value="id"
          :items="industries.records"
          required
          class="mt-5"
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

import useDistributorProfileForm from '../../composables/useDistributorProfileForm'

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      industries, loadIndustries, countries, loadCountries,
    } = useDistributorProfileForm()
    loadIndustries()
    loadCountries()

    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('email', ''),
      ...field('country_code', '852'),
      ...field('phone', ''),
      ...field('country_id', null),
      ...field('industry_id', null),
      ...field('name', ''),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      validate,
      required,

      industries,
      loadIndustries,
      loadCountries,
      countries,
      props,
      t,
    }
  },
}
</script>
