var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('h2', [_vm._v(" Profile ")]), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Email",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Name - editable by frontend",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Country Code",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": ['852', '853', '86'],
      "rules": []
    },
    model: {
      value: _vm.form.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "country_code", $$v);
      },
      expression: "form.country_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Phone",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1), _vm.countries ? _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Country",
      "outlined": "",
      "item-text": "name.en",
      "rules": [function (v) {
        return !!v || 'Item is required';
      }],
      "item-value": "id",
      "items": _vm.countries.records,
      "required": ""
    },
    model: {
      value: _vm.form.country_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "country_id", $$v);
      },
      expression: "form.country_id"
    }
  }) : _vm._e(), _vm.industries ? _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Industry",
      "outlined": "",
      "item-text": "name.en",
      "rules": [function (v) {
        return !!v || 'Item is required';
      }],
      "item-value": "id",
      "items": _vm.industries.records,
      "required": ""
    },
    model: {
      value: _vm.form.industry_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "industry_id", $$v);
      },
      expression: "form.industry_id"
    }
  }) : _vm._e()], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }