var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "label": "Type",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": _vm.typeOptions,
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('div', [_c('h3', {
    staticClass: "d-flex justify-space-between"
  }, [_vm._v(" Enabled Features "), _c('a', {
    on: {
      "click": _vm.addAllModules
    }
  }, [_vm._v("ADD ALL FEATURES")])]), _vm._l(_vm.moduleFeaturesOptions, function (mOptions) {
    return _c('div', {
      key: mOptions.id,
      staticClass: "d-flex justify-space-between align-center"
    }, [_c('v-select', {
      attrs: {
        "items": mOptions.features,
        "item-text": "label",
        "item-value": "value",
        "label": "".concat(_vm.t(mOptions.name), " Module"),
        "chips": "",
        "multiple": ""
      },
      model: {
        value: _vm.form.feature_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "feature_ids", $$v);
        },
        expression: "form.feature_ids"
      }
    }), _c('div', {
      staticStyle: {
        "min-width": "130px"
      }
    }, [_c('a', {
      on: {
        "click": function click($event) {
          return _vm.allowAllModuleFeatures(mOptions);
        }
      }
    }, [_vm._v(" Allow all ")]), _c('a', {
      staticClass: "ml-2",
      on: {
        "click": function click($event) {
          return _vm.blockAllModuleFeatures(mOptions);
        }
      }
    }, [_vm._v(" Block all ")])])], 1);
  })], 2), _c('h3', [_vm._v("General Infomation")]), _c('v-select', {
    attrs: {
      "items": _vm.siteOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Site",
      "rules": [_vm.required],
      "outlined": "",
      "chips": ""
    },
    model: {
      value: _vm.form.site_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "site_id", $$v);
      },
      expression: "form.site_id"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Business Name",
      "outlined": "",
      "hint": "Printed on Official Docs.",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.business_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "business_name", $$v);
      },
      expression: "form.business_name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Nice Name",
      "outlined": "",
      "hint": "Simple and recognizable",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.nicename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nicename", $$v);
      },
      expression: "form.nicename"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Unique slug for nice URL (e.g. MIX)",
      "hint": "This affects sub domain white label ({BLUE}.portal. - case insensitive). The frontend needs this determine current sub domain's distributor. Keep it unchanged once confirmed. Otherwise, the existing url sent will fail. It no longer affect the medical prefix.",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slug", $$v);
      },
      expression: "form.slug"
    }
  }), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.active ? 'Active' : 'Inactive'
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }), _c('p', [_vm._v("Don't forget to update the distributor profile!")])], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }