import { useNotifyErrors } from '@/composables'
import { fetchCountries } from '@api/distributor/country'
import { fetchIndustries } from '@api/distributor/industry'
import { ref } from '@vue/composition-api'

const cached = []

export default function useList() {
  const industries = ref(null)

  const loadIndustries = () =>
    fetchIndustries({ take: 999 })
      .then(({ data }) => {
        industries.value = data.data
      })
      .catch(useNotifyErrors)

  const countries = ref(null)

  const loadCountries = () =>
    fetchCountries({ take: 999 })
      .then(({ data }) => {
        countries.value = data.data
      })
      .catch(useNotifyErrors)

  return {
    industries,
    loadIndustries,
    countries,
    loadCountries,
  }
}
